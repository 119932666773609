/* import { getSessionToken } from "../http/auth";
import { API } from "../http/api"; */
import i18n from '../locales/index';
import axios from "axios";
import store from '.';

const chat = {
    namespaced: true,
    state: {
        msgs: [],
        currentPrompt: '',
        loading: false,
        chatId: '',
        messageId: '',
        lastMessage: '',
        getLastQuestion: ''
    },
    mutations: {
        setLastMessage(state, payload) {
            state.lastMessage = payload;
        },
        setLastQuestion(state, payload) {
            state.lastQuestion = payload;
        },
        pushMsg(state, payload) {
            state.msgs.splice(state.msgs.length, 0, payload);
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        popMsg(state) {
            state.msgs.splice(state.msgs.length - 1, 1);
        },
        setChatId(state, payload) {
            state.chatId = payload;
        },
        setMessageId(state, payload) {
            state.messageId= payload;
        },
        clearMsgs(state) {
            state.msgs = [];
        }
    },
    actions: {
        showPrompt({commit, dispatch}, data) {
            commit('pushMsg', {
                sender: 'User',
                content: data.formattedContent,
            });
            commit('pushMsg', {
                sender: 'QImpactTab',
                loading: true
            });
            dispatch('sendPrompt',({commit, dispatch}, data))
        },
        async sendPrompt({commit, dispatch}, data) {  
            let userPlan = this.getters['profile/getUserPlan'];
            console.log("PLAN:", userPlan);
            axios.post('https://impactchat-api.dev.physisinvestment.com:8000/chat', {question: data.formattedContent, userID: store.getters['profile/getAdvisorId'],plan: userPlan})
                .then(reply => {

                commit('setChatId', "asd");
                commit('setMessageId', "asd");
                dispatch('answerPrompt');


        
                const responseData1 = reply.data.data.elements;
                let flag = reply.data.flag;
                const idElement1 = Object.keys(responseData1);
                if(idElement1.length<7){
                for(let p =0; p<=idElement1.length-1;p++){
                    const reorganizedData = {};

                    let companyName = "";

                for (const item of responseData1[idElement1[p]]) {
                    companyName = idElement1[p];
                    const { indicator_name } = item;
                
                    if (!reorganizedData[indicator_name]) {
                        reorganizedData[indicator_name] = [];
                    }
                
                    reorganizedData[indicator_name].push(item);

                }

                let responseData = [];

                for (const key of Object.keys(reorganizedData)) {
                    responseData=[];
                    responseData.push(reorganizedData[key]);
                
                
                if (responseData) {
                    let chartMsg = {};
                    const years = [];
                    const series =[];
                    let arrSeries={};
                    let aux=false;
                    let arrElementos = [];
                    let unitIndex = "";
                    let isGraphic = false;
                    for (const [key, value] of Object.entries(responseData)  ) {
                        if (key !== 'unit') { 
                            arrElementos.push({ [key]: value }); 
                        }
                    }
                    const idElement = Object.keys(arrElementos);
                    for(let i=0;i<Object.keys(arrElementos).length;i++){
                        const element = Object.keys(arrElementos[idElement[i]])
                        const ArrayValue = arrElementos[idElement[i]];
                        const values = [];
                        if(responseData[element][0] !== undefined){
                            unitIndex= responseData[element][0];
                            isGraphic = true;
                        }
                        ArrayValue[element].forEach(dato => {
                            let valorFinal = dato.value                                    
                            let yearValue="";
                            if(ArrayValue[element].length>1){
                              yearValue=dato.year;
                            }
                              else{
                                  yearValue=ArrayValue[element][0].id
                                  aux=true;
                              }
                            years.push(yearValue);
                            if (valorFinal % 1 !== 0 && valorFinal !== "")  {
                                // Limitar el número a dos decimales usando toFixed()
                                valorFinal=Math.round(valorFinal*100)/100;
                            }
                            values.push(valorFinal);
                            const valorAux = aux ? [valorFinal] : values;
                            arrSeries={
                              name: responseData[element][0].indicator_name,
                              data: valorAux,
                            }

                          });

                          series.push(arrSeries);
                      }
                      let auxYears =[];
                      auxYears.push(unitIndex.year);
                      const xaxisName = aux ? auxYears : years;
                      
                          const xaxis={
                              "type":"category",
                              "categories": xaxisName,
                          }
                    const yaxis={
                        labels: {

                              formatter: function(value) {
                                if (value >= 1000000) {
                                    return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                } else if (value >= 1000) {
                                    return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                } else {
                                    return value.toString();
                                }
                            }
                          },
                        "title":{
                            "text":unitIndex.indicator_name +" in "+unitIndex.unit,                                            
                        },

                    }
                    if(years.length>1 && aux===false){
                    chartMsg = {
                        chart: true,
                        chartType: 'area',
                        content: {
                            type: 'area',
                            xaxis: xaxis,
                            stroke: {
                                curve: 'smooth'
                            },
                            title: {
                                text: companyName
                            },
                            yaxis: yaxis,
                            series: series
                        }
                    };
                }
                else{
                    chartMsg = {
                        chart: true,
                        chartType: 'column',
                        content: {
                            type: 'column',
                            xaxis: xaxis,
                            stroke: {
                                curve: 'smooth'
                            },
                            title: {
                                text: companyName
                            },
                            yaxis: yaxis,
                            series: series
                        }
                    };
                }

                if(years.length>=1 && isGraphic===true){
                    setTimeout(() => {
                        commit('pushMsg', {
                            sender: 'QImpactTab',
                            id: reply.data.responseID,
                            loading: false,
                            ...chartMsg
                        });
                    });
                }

                

                }
                else{
                    const answer = responseData;
                    commit('pushMsg', {
                        sender: 'QImpactTab',
                        content: answer,
                        loading: false,
                        id: reply.data.responseID
                    });
                }
            }

            }
        }
        else{
            flag = 6;
        }
            commit('popMsg');

            let textResponse = "";
            switch (flag) {
                case 0:
                    textResponse = reply.data.response
                break;
                case 1:
                    textResponse = 'The information you\'ve requested is not included in the starter package. <a href="/platform/profile/Plan">Please upgrade to view this data and more!</a>';
                break;
                case 2:
                    textResponse = "I'm sorry, but I could not identify the company you're asking about. Can you check the spelling and try again?";
                break;
                case 3:
                    textResponse = "I'm sorry, but I do not recognize that indicator. Please select a similar indicator and try again.";
                break;
                case 4:
                    textResponse = reply.data.response;
                break;
                case 5:
                    textResponse = "I'm sorry, but I didn't understand your question. Could you please rephrase it?";
                break;
                case 6:
                    textResponse = "Too many indicators selected. Please select a maximum of 6 indicators.";
                break;
                

            }
            setTimeout(() => {
                commit('pushMsg', {
                    sender: 'QImpactTab',
                    content: textResponse,
                    id: reply.data.responseID,
                    loading: false,
                });
            });
                commit('setLastMessage', reply.data.response);
                commit('setLastQuestion', data.formattedContent);
            }).catch(err => {
                if (err.message && err.message.includes('401')) {
                    dispatch('handleExpiredSession', {}, {root: true});
                }
                else {
                    commit('setLoading', false);
                    commit('popMsg');
                    setTimeout(() => {
                        commit('pushMsg', {
                            sender: 'QImpactTab',
                            content: i18n.global.t('chat.error.genericError'),
                            loading: false
                        });
                    });
                    if (!err.toString().includes('422')) {
                        throw err;
                    }
                }
            })
        },      
         async setGreetingMsg({commit, dispatch}) {
            axios.get('https://impactchat-api.dev.physisinvestment.com:8000/history?userID='+store.getters['profile/getAdvisorId'])
            .then(rply => {
                if(rply.data.length>0){
                        commit('setChatId', "asd");
                        commit('setMessageId', "asd");
                        dispatch('answerPrompt');
        
        
                const reply = rply.data[0];
                for(let i=0;i<reply.length;i++){
                    let dataObject = "";
                    if(reply[i].startsWith("question:")){
                        let nuevaCadena = reply[i].replace(/^question:/, '');
                        setTimeout(() => {
                            commit('pushMsg', {
                                sender: 'User',
                                content: nuevaCadena.trim(),
                                loading: false,
                            });
                        });
                    }
                    else{
                        // Reemplazar las comillas simples por dobles fuera del contenido de "response"
                        const validJSONString = reply[i].replace(/('response':\s*"[^"]*")|'/g, (match, p1) => p1 ? match : '"');

                        const regex = /'response'/g;
                        const newText = validJSONString.replace(regex, '"response"');

                        // Convertir a objeto JavaScript
                        dataObject = JSON.parse(newText);

                        // Usar el objeto dataObject como desees
                        
                        
                        const responseData1 = dataObject.data.elements;
                        const flag = dataObject.flag;
                        const idElement1 = Object.keys(responseData1);
                        for(let p =0; p<=idElement1.length-1;p++){
                            const reorganizedData = {};

                            let companyName = "";
                        for (const item of responseData1[idElement1[p]]) {
                            companyName=idElement1[p];
                            const { indicator_name } = item;
                        
                            if (!reorganizedData[indicator_name]) {
                                reorganizedData[indicator_name] = [];
                            }
                        
                            reorganizedData[indicator_name].push(item);
                        }

                        let responseData = [];
        
                        for (const key of Object.keys(reorganizedData)) {
                            responseData=[];
                            responseData.push(reorganizedData[key]);
                        
                        
                        if (responseData) {
                            let chartMsg = {};
                            const years = [];
                            const series =[];
                            let arrSeries={};
                            let aux=false;
                            let arrElementos = [];
                            let unitIndex = "";
                            let isGraphic = false;
                            for (const [key, value] of Object.entries(responseData)  ) {
                                if (key !== 'unit') { 
                                    arrElementos.push({ [key]: value }); 
                                }
                            }
                            const idElement = Object.keys(arrElementos);
                            for(let i=0;i<Object.keys(arrElementos).length;i++){
                                const element = Object.keys(arrElementos[idElement[i]])
                                const ArrayValue = arrElementos[idElement[i]];
                                const values = [];
                                if(responseData[element][0] !== undefined){
                                    unitIndex= responseData[element][0];
                                    isGraphic = true;
                                }
                                ArrayValue[element].forEach(dato => {
                                    let valorFinal = dato.value                                    
                                    let yearValue="";
                                    if(ArrayValue[element].length>1){
                                      yearValue=dato.year;
                                    }
                                      else{
                                          yearValue=ArrayValue[element][0].id
                                          aux=true;
                                      }
                                    years.push(yearValue);
                                    if (valorFinal % 1 !== 0 && valorFinal !== "")  {
                                        // Limitar el número a dos decimales usando toFixed()
                                        valorFinal=Math.round(valorFinal*100)/100;
                                    }
                                    values.push(valorFinal);
                                    const valorAux = aux ? [valorFinal] : values;
                                    arrSeries={
                                      name: responseData[element][0].indicator_name,
                                      data: valorAux,
                                    }
        
                                  });
        
                                  series.push(arrSeries);
                              }
                              let auxYears =[];
                              auxYears.push(unitIndex.year);
                              const xaxisName = aux ? auxYears : years;
                              
                                  const xaxis={
                                      "type":"category",
                                      "categories": xaxisName,
                                  }
                            const yaxis={
                                labels: {
        
                                      formatter: function(value) {
                                        if (value >= 1000000) {
                                            return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                        } else if (value >= 1000) {
                                            return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                        } else {
                                            return value.toString();
                                        }
                                    }
                                  },
                                "title":{
                                    "text":unitIndex.indicator_name +" in "+unitIndex.unit,                                            
                                },
        
                            }
                            if(years.length>1 && aux===false){
                            chartMsg = {
                                chart: true,
                                chartType: 'area',
                                content: {
                                    type: 'area',
                                    xaxis: xaxis,
                                    stroke: {
                                        curve: 'smooth'
                                    },
                                    title: {
                                        text: companyName
                                    },
                                    yaxis: yaxis,
                                    series: series
                                }
                            };
                        }
                        else{
                            chartMsg = {
                                chart: true,
                                chartType: 'column',
                                content: {
                                    type: 'column',
                                    xaxis: xaxis,
                                    stroke: {
                                        curve: 'smooth'
                                    },
                                    title: {
                                        text: companyName
                                    },
                                    yaxis: yaxis,
                                    series: series
                                }
                            };
                        }
                        commit('popMsg');
        
                        if(years.length>=1 && isGraphic===true){
                            setTimeout(() => {
                                commit('pushMsg', {
                                    sender: 'QImpactTab',
                                    loading: false,
                                    ...chartMsg
                                });
                            });
                        }
        
                        
        
                        }
                        else{
                            const answer = responseData;
                            commit('pushMsg', {
                                sender: 'QImpactTab',
                                content: answer,
                                loading: false
                            });
                        }
                    }
                }
                    let textResponse = "";
                        switch (flag) {
                            case 0:
                                textResponse = dataObject.response
                            break;
                            case 1:
                                textResponse = 'The information you\'ve requested is not included in the starter package. <a href="/platform/profile/Plan">Please upgrade to view this data and more!</a>';
                            break;
                            case 2:
                                textResponse = "I'm sorry, but I could not identify the company you're asking about. Can you check the spelling and try again?";
                            break;
                            case 3:
                                textResponse = "I'm sorry, but I do not recognize that indicator. Please select a similar indicator and try again.";
                            break;
                            case 4:
                                textResponse = dataObject.response;
                            break;
                            case 5:
                                textResponse = "I'm sorry, but I didn't understand your question. Could you please rephrase it?";
                            break;
                            
        
                        }
                        setTimeout(() => {
                            commit('pushMsg', {
                                sender: 'QImpactTab',
                                content: textResponse,
                                loading: false,
                            });
                        });
                            commit('setLastMessage', responseData1.response);
                            //commit('setLastQuestion', data.formattedContent);
                    }
                    }
                }else{
                    const greetingsMsg = "Hello! I'm ImpactChat.AI, the latest AI tool from Physis Investment. I'm still in the tuning phase, but don't worry, I will learn from our interactions. I'm here to answer your questions about sustainability. Just ask!";
                    commit('pushMsg', {
                        sender: 'QImpactTab',
                        content: greetingsMsg
                    });
                } 
                    })

    },

},

    getters: {
        getLastQuestion: state => {
            return state.lastQuestion;
        },
        getLastMessage: state => {
            return state.lastMessage;
        },
        getMsgs: state => {
            return state.msgs;
        },
        getCurrentPrompt: state => {
            return state.currentPrompt;
        },
        getLoading: state => {
            return state.loading;
        },
        getChatId: state => {
            return state.chatId;
        },
        getMessageId: state => {
            return state.messageId;
        }
    }
}

export default chat
